import 'bootstrap/dist/css/bootstrap.min.css';
import Collage from './components/collage'
import HomeComponent from './components/home'
import Media from './components/media'
import './App.css';
import './output.css';
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

const icons = ['alpha-femmele.svg','bad-texter.svg','bed-chemistry.svg','bed-marinating.svg','black-cat-girlfriend.svg','blowfishing.svg','cherry-bombing.svg','cinnamon-roll.svg','code-names.svg','contra-dating.svg','d-ai-ting.svg','dating-criteria.svg','dating-diary.svg','delusionship.svg','dry-dating.svg','emoji.svg','eyecontactship.svg','fade-to-fall.svg','fastest-growing-interest.svg','flickering.svg','flowmance.svg','friends-to-lovers.svg','gif.svg','global-top-interests.svg','golden-retiever-boyfriend.svg','good-boy-bad-date.svg','groundhog-dating.svg','hopecore.svg','horoscope.svg','hot-rodent-boyfriend.svg','ins-outs.svg','investi-dating.svg','kissmet.svg','lavender-marriages.svg','limerence.svg','logo.svg','looking-for.svg','loud-breackups-era.svg','loud-looking.svg','love-potion-ing.svg','love-styles.svg','man-in-finance.svg','mbti.svg','nano-ships.svg','paperclipping.svg','passport-passion.svg','phonezoned.svg','pitch-me-dating.svg','remix-relationships.svg','run-club.svg','s-low-effort.svg','season-one-stars.svg','secretship.svg','self-care-soulmates.svg','slow-a-peel.svg','slow-burn.svg','smashing-the-datriarchy.svg','soft-girl.svg','soft-swinging.svg','stack-dating.svg','summer-shading.svg','tennis-triangle.svg','the-snap-gap.svg','three-month-rule.svg','top-dates.svg','top-icks.svg','top-partnership-types.svg','trad-wife.svg','unscripted-dating.svg','white-flag.svg','winter-arc.svg']

export default function App() {
  return (
    <Router>
      <div className="min-h-screen bg-black flex flex-col">
        <main className="flex-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/build-your-own" element={<BuildYourOwn />} />
            <Route path="/media-kits" element={<MediaKits />} />
          </Routes>
        </main>
      </div>
    </Router>
  )
}

function Home() {
  return <HomeComponent />
}

function BuildYourOwn() {
  return <Collage icons={icons}/>
}

function MediaKits() {
  return <Media />
}
