// Import all SVG components
import { ReactComponent as TopPartnershipTypes } from './icons/top-partnership-types.svg'
import { ReactComponent as AlphaFemmele } from './icons/alpha-femmele.svg'
import { ReactComponent as BedChemistry } from './icons/bed-chemistry.svg'
import { ReactComponent as BedMarinating } from './icons/bed-marinating.svg'
import { ReactComponent as BlackCatGirlfriend } from './icons/black-cat-girlfriend.svg'
import { ReactComponent as Blowfishing } from './icons/blowfishing.svg'
import { ReactComponent as CherryBombing } from './icons/cherry-bombing.svg'
import { ReactComponent as CinnamonRoll } from './icons/cinnamon-roll.svg'
import { ReactComponent as CodeNames } from './icons/code-names.svg'
import { ReactComponent as ContraDating } from './icons/contra-dating.svg'
import { ReactComponent as DAITing } from './icons/d-ai-ting.svg'
import { ReactComponent as DatingDiary } from './icons/dating-diary.svg'
import { ReactComponent as Delusionship } from './icons/delusionship.svg'
import { ReactComponent as DryDating } from './icons/dry-dating.svg'
import { ReactComponent as Eyecontactship } from './icons/eyecontactship.svg'
import { ReactComponent as FadeToFall } from './icons/fade-to-fall.svg'
import { ReactComponent as Flickering } from './icons/flickering.svg'
import { ReactComponent as Flowmance } from './icons/flowmance.svg'
import { ReactComponent as FriendsToLovers } from './icons/friends-to-lovers.svg'
import { ReactComponent as GoldenRetieverBoyfriend } from './icons/golden-retiever-boyfriend.svg'
import { ReactComponent as GoodBoyBadDate } from './icons/good-boy-bad-date.svg'
import { ReactComponent as GroundhogDating } from './icons/groundhog-dating.svg'
import { ReactComponent as Hopecore } from './icons/hopecore.svg'
import { ReactComponent as HotRodentBoyfriend } from './icons/hot-rodent-boyfriend.svg'
import { ReactComponent as InsOuts } from './icons/ins-outs.svg'
import { ReactComponent as InvestiDating } from './icons/investi-dating.svg'
import { ReactComponent as Limerence } from './icons/limerence.svg'
import { ReactComponent as LoudBreackupsEra } from './icons/loud-breackups-era.svg'
import { ReactComponent as LovePotionIng } from './icons/love-potion-ing.svg'
import { ReactComponent as Paperclipping } from './icons/paperclipping.svg'
import { ReactComponent as PassportPassion } from './icons/passport-passion.svg'
import { ReactComponent as Phonezoned } from './icons/phonezoned.svg'
import { ReactComponent as PitchMeDating } from './icons/pitch-me-dating.svg'
import { ReactComponent as RunClub } from './icons/run-club.svg'
import { ReactComponent as SLowEffort } from './icons/s-low-effort.svg'
import { ReactComponent as SeasonOneStars } from './icons/season-one-stars.svg'
import { ReactComponent as Secretship } from './icons/secretship.svg'
import { ReactComponent as SelfCareSoulmates } from './icons/self-care-soulmates.svg'
import { ReactComponent as SlowAPeel } from './icons/slow-a-peel.svg'
import { ReactComponent as SmashingTheDatriarchy } from './icons/smashing-the-datriarchy.svg'
import { ReactComponent as SoftSwinging } from './icons/soft-swinging.svg'
import { ReactComponent as SoftGirl } from './icons/soft-girl.svg'
import { ReactComponent as StackDating } from './icons/stack-dating.svg'
import { ReactComponent as SummerShading } from './icons/summer-shading.svg'
import { ReactComponent as TennisTriangle } from './icons/tennis-triangle.svg'
import { ReactComponent as SlowBurn } from './icons/slow-burn.svg'
import { ReactComponent as TheSnapGap } from './icons/the-snap-gap.svg'
import { ReactComponent as Horoscope } from './icons/horoscope.svg'
import { ReactComponent as LookingFor } from './icons/looking-for.svg'
import { ReactComponent as Emoji } from './icons/emoji.svg'
import { ReactComponent as GlobalTopInterests } from './icons/global-top-interests.svg'
import { ReactComponent as LoveStyles } from './icons/love-styles.svg'
import { ReactComponent as MBTI } from './icons/mbti.svg'
import { ReactComponent as ManInFinance } from './icons/man-in-finance.svg'
import { ReactComponent as BadTexter } from './icons/bad-texter.svg'
import { ReactComponent as TradWife } from './icons/trad-wife.svg'
import { ReactComponent as UnscriptedDating } from './icons/unscripted-dating.svg'
import { ReactComponent as WhiteFlag } from './icons/white-flag.svg'
import { ReactComponent as WinterArc } from './icons/winter-arc.svg'
import { ReactComponent as LoudLooking } from './icons/loud-looking.svg'
import { ReactComponent as Kissmet } from './icons/kissmet.svg'
import { ReactComponent as RemixRelationships } from './icons/remix-relationships.svg'
import { ReactComponent as NanoShips } from './icons/nano-ships.svg'
import { ReactComponent as LavenderMarriages } from './icons/lavender-marriages.svg'
import { ReactComponent as ThreeMonthRule } from './icons/three-month-rule.svg'
import { ReactComponent as DatingCriteria } from './icons/dating-criteria.svg'
import { ReactComponent as TopIcks } from './icons/top-icks.svg'
import { ReactComponent as TopDates } from './icons/top-dates.svg'
import { ReactComponent as Gif } from './icons/gif.svg'
import { ReactComponent as InfoButton } from './icons/info-button.svg'
import { ReactComponent as Like } from './icons/like.svg'
import { ReactComponent as Match } from './icons/match.svg'
import { ReactComponent as SuperLike } from './icons/super-like.svg'
import { ReactComponent as YearInSwipe } from './icons/year-in-swipe-logo.svg'
import { ReactComponent as YearInSwipeFinal } from './icons/year-in-swipe-logo-final.svg'

const icons = [
 {
   "id": 72,
   "type": "2024 Data",
   "name": "Year In Swipe",
   "text": "",
   "component": YearInSwipe,
   "file": "year-in-swipe-logo.svg",
   "x": [0,0],
   "y": [0,0],
   "scale": [1.25,2.4],
   "rotate": [0,0]
 },
 {
   "id": 73,
   "type": "2024 Data",
   "name": "Info Button",
   "text": "Click the icons to discover the dating trends, predictions, and themes that defined our 2024 with Tinder’s Year in Swipe™2024 Vision Board.                                                             Hit the \"Build Your Own\" button in the menu up top to start crafting your own Vision Board for a year of going after what you want!",
   "component": InfoButton,
   "file": "info-button.svg",
   "x": [85,70],
   "y": [0,0],
   "scale": [0.5,1],
   "rotate": [0,0]
 },
 {
   "id": 60,
   "type": "2024 Major Themes",
   "name": "Loud Looking",
   "text": "Let's get loud. Whether it's a \"Man in Finance\" or \"Gamer Girlfriend,\" singles are ditching the vague and getting vocal about their needs and desires, confidently and unapologetically.",
   "component": LoudLooking,
   "file": "loud-looking.svg",
   "x": [30,0],
   "y": [0,150],
   "scale": [3,3.5],
   "rotate": [0,0]
 },
 {
   "id": 10,
   "type": "2024 Trend",
   "name": "D-AI-ting",
   "text": "Using AI as your dating wingman to craft the perfect text, build out your bio, or to practice online conversations to boost your confidence. Love at first byte: 68% of surveyed singles say an AI feature for photo selection assistance would be a game-changer in 2024.",
   "component": DAITing,
   "file": "d-ai-ting.svg",
   "x": [2,40],
   "y": [60,150],
   "scale": [1.2,2.5],
   "rotate": [10,0]
 },
 {
   "id": 2,
   "type": "2024 Trend",
   "name": "Bed Chemistry",
   "text": "A relationship fueled mostly by physical chemistry in bed, where the sparks fly under the sheets. And the science is clear: 58% of singles surveyed said sex is important in a relationship.",
   "component": BedChemistry,
   "file": "bed-chemistry.svg",
   "x": [62.5,60],
   "y": [130,100],
   "scale": [1.2,1.2],
   "rotate": [0,0]
 },
 {
   "id": 51,
   "type": "2024 Data",
   "name": "Jet Setters",
   "text": "Calling all jet-setters and audophiles. In 2024, travel, movies, and music were the top global interests in Tinder bios.",
   "component": GlobalTopInterests,
   "file": "global-top-interests.svg",
   "x": [90,80],
   "y": [60,200],
   "scale": [1.25,2],
   "rotate": [10,5]
 },
 {
   "id": 43,
   "type": "2024 Trend",
   "name": "Summer Shading",
   "text": "As the weather heats up, commitment cools down. Singles are preferring to keep things easy, breezy, and casual. HAGS: 31% of surveyed singles anticipate being most romantically active or dating most in the summer.",
   "component": SummerShading,
   "file": "summer-shading.svg",
   "x": [15,45],
   "y": [60,300],
   "scale": [1,2.5],
   "rotate": [0,10]
 },
 {
   "id": 1,
   "type": "2024 Trend",
   "name": "Alpha Femme",
   "text": "This single isn't waiting around for a knight in shining armor to make the first move; she's confidently taking the lead.",
   "component": AlphaFemmele,
   "file": "alpha-femmele.svg",
   "x": [0,0],
   "y": [130,350],
   "scale": [1,2.1],
   "rotate": [0,0]
 },
 {
   "id": 55,
   "type": "2024 Data",
   "name": "Bad Texter",
   "text": "Hold the phone — In 2024, on Tinder being a “Bad Texter” was the most attractive communication style globally.",
   "component": BadTexter,
   "file": "bad-texter.svg",
   "x": [10,25],
   "y": [120,350],
   "scale": [1,2],
   "rotate": [0,0]
 },
 {
   "id": 47,
   "type": "2024 Data",
   "name": "Horoscope",
   "text": "The stars aligned — In 2024, the most attractive signs in Tinder bios were Virgo, Gemini, and Leo. Plus, 43% of singles surveyed said that astrological love predictions would likely influence how they date in 2025!",
   "component": Horoscope,
   "file": "horoscope.svg",
   "x": [10,25],
   "y": [160,450],
   "scale": [2,6],
   "rotate": [0,0]
 },
 {
   "id": 44,
   "type": "2024 Trend",
   "name": "Tennis Triangle",
   "text": "It's when three people are serving up a charged, dynamic connection that feels like a game of love and tension.",
   "component": TennisTriangle,
   "file": "tennis-triangle.svg",
   "x": [35,70],
   "y": [140,350],
   "scale": [1,3],
   "rotate": [60,20]
 },
 {
   "id": 66,
   "type": "2024 Data",
   "name": "Criteria",
   "text": "Trust, chemistry, and shared values — 2025’s must-haves. Next year's singles are dating with purpose, seeking partners who check all the right boxes.",
   "component": DatingCriteria,
   "file": "dating-criteria.svg",
   "x": [68,80],
   "y": [0,350],
   "scale": [2.25,3.5],
   "rotate": [10,10]
 },
  {
   "id": 30,
   "type": "2024 Trend",
   "name": "Passport Passion",
   "text": "When you get swept up in a romantic fling in a new city, and it feels straight out of a TV rom-com. Pack your bags: Tinder users passport to an average of 4 cities, chasing love and adventure!",
   "component": PassportPassion,
   "file": "passport-passion.svg",
   "x": [0,0],
   "y": [180,500],
   "scale": [1,3.2],
   "rotate": [0,0]
 },
 {
   "id": 29,
   "type": "2024 Trend",
   "name": "Paperclipping",
   "text": "When someone randomly pops back into your life with a casual, digital drop-in — it’s the kind of pointless message you're tempted to ignore.",
   "component": Paperclipping,
   "file": "paperclipping.svg",
   "x": [30,70],
   "y": [120,450],
   "scale": [1,3],
   "rotate": [50,0]
 },
 {
   "id": 31,
   "type": "2024 Trend",
   "name": "Phonezoned",
   "text": "You’re vibing on a date, but there's a third-wheel stealing the spotlight: their phone.",
   "component": Phonezoned,
   "file": "phonezoned.svg",
   "x": [45,60],
   "y": [140,675],
   "scale": [1.25,3],
   "rotate": [20,0]
 },
 {
   "id": 15,
   "type": "2024 Trend",
   "name": "Fade to Fall",
   "text": "When that dreamy summer fling starts to fade as the seasons change.",
   "component": FadeToFall,
   "file": "fade-to-fall.svg",
   "x": [85,80],
   "y": [125,575],
   "scale": [1.5,2.5],
   "rotate": [-10,0]
 },
 {
   "id": 16,
   "type": "2024 Trend",
   "name": "Flickering",
   "text": "When the flame slowly dims, but they haven't fully disappeared. The difference from ghosting? They're still around, but the spark isn't.",
   "component": Flickering,
   "file": "flickering.svg",
   "x": [75,82],
   "y": [125,800],
   "scale": [1.25,2],
   "rotate": [0,0]
 },
 {
   "id": 68,
   "type": "2024 Data",
   "name": "Top-Icks",
   "text": "Bad hygiene, rudeness, and talking too much about exes? In 2025, these instant deal-breakers are projected to be the biggest icks.",
   "component": TopIcks,
   "file": "top-icks.svg",
   "x": [60,70],
   "y": [200,900],
   "scale": [2.25,3.5],
   "rotate": [0,0]
 },
 {
   "id": 53,
   "type": "2024 Data",
   "name": "MBTI",
   "text": "Protagonists, Campaigners, and Mediators — this is your moment! In 2024, the most attractive personality types were ENFP, ENFJ, and INFP.",
   "component": MBTI,
   "file": "mbti.svg",
   "x": [22,20],
   "y": [170,800],
   "scale": [1,3.5],
   "rotate": [0,0]
 },

 {
   "id": 64,
   "type": "2024 Trend",
   "name": "Lavender Marriage",
   "text": "A marriage built on deep trust — not romance, where one or both of the partners are queer. This platonic dynamic is the original \"friends with (legal) benefits.\"",
   "component": LavenderMarriages,
   "file": "lavender-marriages.svg",
   "x": [40,45],
   "y": [200,850],
   "scale": [2,4],
   "rotate": [0,0]
 },
 {
   "id": 52,
   "type": "2024 Data",
   "name": "Personal Mix",
   "text": "For the gift-givers and heart-melters: In 2024, the most attractive love languages were receiving gifts, acts of service, and words of affirmation.",
   "component": LoveStyles,
   "file": "love-styles.svg",
   "x": [15,0],
   "y": [300,1050],
   "scale": [1.5,4],
   "rotate": [0,0]
 },
 {
   "id": 63,
   "type": "2024 Major Themes",
   "name": "Nano-ships",
   "text": "In 2024, no romantic connection was too small to define. From \"eyecontactships\" to \"textuationships,\" singles are embracing even the tiniest interactions as meaningful. It's about reclaiming romance, whether serious or not, and recognizing that even micro-moments can count as a \"thing.\"",
   "component": NanoShips,
   "file": "nano-ships.svg",
   "x": [0,0],
   "y": [240,850],
   "scale": [1.75,3],
   "rotate": [0,0]
 },
 {
   "id": 40,
   "type": "2024 Trend",
   "name": "Soft Swinging",
   "text": "he diet version of swinging where couples enjoy light, playful interactions—like kissing or touching—without fully swapping partners. ",
   "component": SoftSwinging,
   "file": "soft-swinging.svg",
   "x": [22.5,30],
   "y": [225,1150],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 57,
   "type": "2024 Trend",
   "name": "Unscripted Dating",
   "text": "Letting go of the pressure to find \"the one\" and instead following what feels right. It's about enjoying the journey of dating as a path to self-discovery, without worrying about the perfect partner or endgame.",
   "component": UnscriptedDating,
   "file": "unscripted-dating.svg",
   "x": [72.5,60],
   "y": [220,1200],
   "scale": [2.25,4],
   "rotate": [-20,-20]
 },
 {
   "id": 9,
   "type": "2024 Trend",
   "name": "Contra-dating",
   "text": "Ditching your usual \"type\" and stepping outside the box to explore new dating possibilities. This pushes daters to give someone they wouldn't normally go for a chance.",
   "component": ContraDating,
   "file": "contra-dating.svg",
   "x": [0,0],
   "y": [325,1250],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 59,
   "type": "2024 Trend",
   "name": "Winter Arc",
   "text": "A season of personal growth and reflection, when the winter months are used to build new habits and set a fresh focus for the year ahead. Staying warm: 20% of our surveyed singles predict winter will be their most romantically active season in 2025!",
   "component": WinterArc,
   "file": "winter-arc.svg",
   "x": [35,30],
   "y": [300,1350],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 76,
   "type": "2024 Data",
   "name": "Match",
   "text": "",
   "component": Match,
   "file": "match.svg",
   "x": [70,50],
   "y": [275,1300],
   "scale": [1.2,2],
   "rotate": [0,0]
 },
 {
   "id": 18,
   "type": "2024 Trend",
   "name": "Friends-to-Lovers Pipeline",
   "text": "When a friendship gradually turns romantic — the kind where one day you realize your long-time laugh partner might just be your perfect match. 25% of singles we surveyed are on the hunt for \"friendship\" when it comes to dating in 2025.",
   "component": FriendsToLovers,
   "file": "friends-to-lovers.svg",
   "x": [55,50],
   "y": [300,1450],
   "scale": [1,2.5],
   "rotate": [0,0]
 },
 {
   "id": 24,
   "type": "2024 Trend",
   "name": "Ins/Outs",
   "text": "When daters create a list of qualities they want in a relationship, helping them date with intention and stay focused on what truly matters in a relationship.",
   "component": InsOuts,
   "file": "ins-outs.svg",
   "x": [65,75],
   "y": [320,1425],
   "scale": [2.5,4.5],
   "rotate": [10,10]
 },
 {
   "id": 62,
   "type": "2024 Major Themes",
   "name": "Remix Relationships",
   "text": "In today's polarized world, differences like age, work ethic, or critical thinking don't have to be dealbreakers but strengths. These mixed partnerships are all about reclaiming the divides and finding compatibility beyond perfect symmetry. ",
   "component": RemixRelationships,
   "file": "remix-relationships.svg",
   "x": [0,0],
   "y": [400,1450],
   "scale": [2,3],
   "rotate": [-20,-20]
 },
 {
   "id": 75,
   "type": "2024 Data",
   "name": "Super Like",
   "text": "",
   "component": SuperLike,
   "file": "superlike.svg",
   "x": [5,5],
   "y": [465,1575],
   "scale": [1,2.5],
   "rotate": [-8,-3]
 },
 {
   "id": 25,
   "type": "2024 Trend",
   "name": "Investi-dating",
   "text": "When you and your friends go full detective mode, digging up your crush's entire online footprint (or their aunt's 2015 trip to Iceland). In fact, 63% of Tinder's surveyed singles pre-screened their dates!",
   "component": InvestiDating,
   "file": "investi-dating.svg",
   "x": [25,30],
   "y": [350,1550],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 5,
   "type": "2024 Trend",
   "name": "Blowfishing",
   "text": "A catfish fakes their identity — a blowfish inflates their lifestyle or exaggerates to seem more impressive than it actually is.",
   "component": Blowfishing,
   "file": "blowfishing.svg",
   "x": [82.5,60],
   "y": [335,1550],
   "scale": [1.25,2.5],
   "rotate": [0,0]
 },
 {
   "id": 45,
   "type": "2024 Trend",
   "name": "The Slow Burn",
   "text": "A relationship that builds slowly and steadily, fueled by mutual respect and clear boundaries from the beginning. It's the kind of connection that takes time to heat up but often leads to something lasting and meaningful.",
   "component": SlowBurn,
   "file": "slow-burn.svg",
   "x": [80,65],
   "y": [400,1700],
   "scale": [2,4],
   "rotate": [0,0]
 },
 {
   "id": 20,
   "type": "2024 Trend",
   "name": "Good Dude, Bad Date",
   "text": "Someone who's an awesome friend but drops the ball when it comes to romance. Good to hang with, not so great to date.",
   "component": GoodBoyBadDate,
   "file": "good-boy-bad-date.svg",
   "x": [0,0],
   "y": [500,1700],
   "scale": [2,3.5],
   "rotate": [0,0]
 },
 {
   "id": 48,
   "type": "2024 Data",
   "name": "Looking For",
   "text": "Eyes up! The top-ranked bio mention on Tinder in 2024 was \"looking for,\" proving that singles were being clear about their intentions even before matching.",
   "component": LookingFor,
   "file": "looking-for.svg",
   "x": [60,40],
   "y": [650,1750],
   "scale": [1.5,2.5],
   "rotate": [0,0]
 },
 {
   "id": 67,
   "type": "2024 Data",
   "name": "Top Partner",
   "text": "Loyal, sweet, or financially stable? Golden Retriever, Cinnamon Roll, and Partner in Finance top the list for 2025’s ideal partner types.",
   "component": TopPartnershipTypes,
   "file": "top-partnership-types.svg",
   "x": [74,64],
   "y": [650,1850],
   "scale": [2.5,4],
   "rotate": [0,5]
 },
 {
   "id": 19,
   "type": "2024 Trend",
   "name": "Golden Retriever Boyfriend",
   "text": "The human equivalent of a big, lovable, golden boy pup. He's loyal, laid-back, and gives you all his attention and affection without hesitation. Puppy love: 44% of our surveyed singles said they're looking for a \"golden retriever\" in 2025.",
   "component": GoldenRetieverBoyfriend,
   "file": "golden-retiever-boyfriend.svg",
   "x": [60,35],
   "y": [700,2000],
   "scale": [1.2,3.5],
   "rotate": [0,0]
 },
 {
   "id": 22,
   "type": "2024 Trend",
   "name": "Hopecore",
   "text": "A trend all about embracing positivity and joy in dating and life. Surveyed singles say that to stay energized and excited about dating, they try to stay positive (41%), have an open-mind (39%), and remember to have fun (33%).",
   "component": Hopecore,
   "file": "hopecore.svg",
   "x": [5,0],
   "y": [580,1875],
   "scale": [2.5,4.5],
   "rotate": [10,8]
 },
 {
   "id": 21,
   "type": "2024 Trend",
   "name": "Groundhog Dating",
   "text": "Taking your dates to the same spot every time, sticking to familiar territory to keep those first-date jitters in check. It's dating, but where everyone knows your name.",
   "component": GroundhogDating,
   "file": "groundhog-dating.svg",
   "x": [0,0],
   "y": [650,2000],
   "scale": [2,2.5],
   "rotate": [0,0]
 },
 {
   "id": 65,
   "type": "2024 Trend",
   "name": "Three Month Rule",
   "text": "Three months deep with no DTR? According to the rule, if a relationship is not exclusive within three months of dating, it will likely stay casual.",
   "component": ThreeMonthRule,
   "file": "three-month-rule.svg",
   "x": [40,15],
   "y": [450,2000],
   "scale": [2,3],
   "rotate": [0,0]
 },
 {
   "id": 7,
   "type": "2024 Trend",
   "name": "Cinnamon Roll",
   "text": "A genuinely sweet, kind, and supportive person who's too pure for this world. They leave their ego behind and bring nothing but warmth and ooey-gooey-goodness. Keep it sweet: 35% of surveyed Tinder singles say they are looking for a \"cinnamon roll\" in 2025.",
   "component": CinnamonRoll,
   "file": "cinnamon-roll.svg",
   "x": [60,70],
   "y": [580,2150],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 54,
   "type": "2024 Data",
   "name": "Finance",
   "text": "Did you know? In 2024, there was an 82% increase in mentions of  \"finance\" in Tinder bios.",
   "component": ManInFinance,
   "file": "man-in-finance.svg",
   "x": [80,40],
   "y": [580,2200],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 32,
   "type": "2024 Trend",
   "name": "Pitch-Me Dating",
   "text": "Hello, Sharks! Singles are using decks, business cards, and pitches to sell themselves as the ideal partner. Dating meets personal branding: 11% of our surveyed singles said that \"pitch-me dating\" appealed to them right now.",
   "component": PitchMeDating,
   "file": "pitch-me-dating.svg",
   "x": [15,0],
   "y": [470,2150],
   "scale": [2.5,4.5],
   "rotate": [0,10]
 },
 {
   "id": 3,
   "type": "2024 Trend",
   "name": "Bed Marinating",
   "text": "A cozy, positive twist on \"bed rotting.\" It's all about couples spending quality time together, relaxing and recharging side-by-side. Pull back the covers: In 2024, the most common \"love style\" listed in Tinder bios was \"Time Together.\"",
   "component": BedMarinating,
   "file": "bed-marinating.svg",
   "x": [40,35],
   "y": [550,2350],
   "scale": [3,5],
   "rotate": [10,10]
 },
 {
   "id": 34,
   "type": "2024 Trend",
   "name": "S-low Effort",
   "text": "A cultural shift that encourages \"not doing too much\" when dating.",
   "component": SLowEffort,
   "file": "s-low-effort.svg",
   "x": [85,65],
   "y": [500,2300],
   "scale": [1.5,3.5],
   "rotate": [0,0]
 },
 {
   "id": 46,
   "type": "2024 Trend",
   "name": "The Snap Gap",
   "text": "When you show off photos of your crush but insist: \"They're way better in person.\"",
   "component": TheSnapGap,
   "file": "the-snap-gap.svg",
   "x": [57,67],
   "y": [440,2525],
   "scale": [1.8,3.5],
   "rotate": [0,0]
 },
 {
   "id": 70,
   "type": "2024 Data",
   "name": "Top Dates",
   "text": "Fresh air fun: In 2025, 39% of singles are planning to make outdoor activities, like hiking and picnicking, their go-to date.",
   "component": TopDates,
   "file": "top-dates.svg",
   "x": [25,0],
   "y": [420,2400],
   "scale": [1.5,3.5],
   "rotate": [0,0]
 },
 {
   "id": 17,
   "type": "2024 Trend",
   "name": "Flowmance",
   "text": "The kind of connection where someone fits so seamlessly into your life that you don't have to change who you are. It's about being together while still feeling like individuals.",
   "component": Flowmance,
   "file": "flowmance.svg",
   "x": [15,5],
   "y": [650,2550],
   "scale": [1,3],
   "rotate": [0,10]
 },
 {
   "id": 8,
   "type": "2024 Trend",
   "name": "Code Names",
   "text": "When you're juggling a dating roster and have to assign each person a nickname to keep things straight.",
   "component": CodeNames,
   "file": "code-names.svg",
   "x": [40,35],
   "y": [700,2650],
   "scale": [1,2.5],
   "rotate": [0,0]
 },
 {
   "id": 23,
   "type": "2024 Trend",
   "name": "Hot Rodent Boyfriend",
   "text": "The unconventionally hot guy with a pointed, almost rodent-like face and a whole lot of swag. With messy hair, he rocks vintage clothes and looks like he's one cigarette away from a brooding indie film. 21% of our surveyed singles said they're looking for a \"hot rodent\" in 2025.",
   "component": HotRodentBoyfriend,
   "file": "hot-rodent-boyfriend.svg",
   "x": [40,50],
   "y": [350,2650],
   "scale": [2,4],
   "rotate": [0,0]
 },
 {
   "id": 61,
   "type": "2024 Major Themes",
   "name": "Kissmet",
   "text": "A twist on \"kismet,\" this year's dating scene embraced the magic of unscripted moments. From meet-cutes to spontaneous first dates, singles are leaning into the joy of organic, genuine connections over following the typical dating formula.",
   "component": Kissmet,
   "file": "kissmet.svg",
   "x": [70,70],
   "y": [455,2700],
   "scale": [2,3],
   "rotate": [10,-20]
 },
 {
   "id": 14,
   "type": "2024 Trend",
   "name": "Eyecontactship",
   "text": "A connection built entirely on intense eye contact — an unspoken bond without words.",
   "component": Eyecontactship,
   "file": "eyecontactship.svg",
   "x": [10,0],
   "y": [720,2775],
   "scale": [2.5,3.5],
   "rotate": [0,10]
 },
 {
   "id": 58,
   "type": "2024 Trend",
   "name": "White Flag",
   "text": "An experience or person that inspires you to surrender to the universe, put down your defenses, and open yourself up to love.",
   "component": WhiteFlag,
   "file": "white-flag.svg",
   "x": [30,30],
   "y": [750,2900],
   "scale": [1.2,3],
   "rotate": [0,-10]
 },
 {
   "id": 49,
   "type": "2024 Data",
   "name": "Flirt Alert",
   "text": "In 2024, the top global emojis were ♂😉 ❤, showing playful and flirty language led the way.",
   "component": Emoji,
   "file": "emoji.svg",
   "x": [58,55],
   "y": [780,2900],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 56,
   "type": "2024 Trend",
   "name": "Tradwife",
   "text": "A dating style that embraces traditional gender roles, cooking up the perfect provider husband — from scratch, of course.",
   "component": TradWife,
   "file": "trad-wife.svg",
   "x": [75,75],
   "y": [780,2800],
   "scale": [2,3],
   "rotate": [0,0]
 },
 {
   "id": 41,
   "type": "2024 Trend",
   "name": "Soft-Girl",
   "text": "Move over girlboss, singles are all about \"soft values\" — even if that means skipping work. They're living life gently and on their own terms.",
   "component": SoftGirl,
   "file": "soft-girl.svg",
   "x": [0,0],
   "y": [750,2850],
   "scale": [2,3.5],
   "rotate": [0,0]
 },
 {
   "id": 37,
   "type": "2024 Trend",
   "name": "Self-care Soulmates",
   "text": "When you and your connection both prioritize self-care and embrace your mental health journeys to show up as better partners. 31% of our surveyed singles say that to stay energized and excited about dating, they prioritize self-care.",
   "component": SelfCareSoulmates,
   "file": "self-care-soulmates.svg",
   "x": [15,20],
   "y": [800,3050],
   "scale": [2,4],
   "rotate": [0,0]
 },
 {
   "id": 42,
   "type": "2024 Trend",
   "name": "Stack Dating",
   "text": "Lining up multiple dates in a day to streamline your love life — and get extra mileage out of that date fit.",
   "component": StackDating,
   "file": "stack-dating.svg",
   "x": [35,55],
   "y": [875,3100],
   "scale": [1,2.5],
   "rotate": [0,-10]
 },
 {
   "id": 36,
   "type": "2024 Trend",
   "name": "Secretship",
   "text": "A situationship you hide from your friends because, deep down, you know they're not the best for you. It's your little secret.Secretship",
   "component": Secretship,
   "file": "secretship.svg",
   "x": [80,80],
   "y": [850,2950],
   "scale": [2,3],
   "rotate": [10,20]
 },
 {
   "id": 74,
   "type": "2024 Data",
   "name": "Like",
   "text": "",
   "component": Like,
   "file": "like.svg",
   "x": [45,70],
   "y": [950,3150],
   "scale": [1.5,2.5],
   "rotate": [0,0]
 },
 {
   "id": 11,
   "type": "2024 Trend",
   "name": "Dating Diaries",
   "text": "Sharing (and oversharing) the highs and lows of modern dating on social media.",
   "component": DatingDiary,
   "file": "dating-diary.svg",
   "x": [0,0],
   "y": [850,3100],
   "scale": [2,3],
   "rotate": [0,0]
 },
 {
   "id": 6,
   "type": "2024 Trend",
   "name": "Cherry Bombing",
   "text": "Dropping small, playful flirtations instead of going all out with grand romantic gestures. It's all about those subtle sparks that keep things light and fun. Cherry on top: \"Thoughtful Gestures\" ranks as the second most attractive \"love style\" in Tinder bios, according to users.",
   "component": CherryBombing,
   "file": "cherry-bombing.svg",
   "x": [0,0],
   "y": [940,3300],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 71,
   "type": "2024 Data",
   "name": "GIF Game Strong",
   "text": "On Tinder, an average of 52 million GIFs are sent every week!",
   "component": Gif,
   "file": "gif.svg",
   "x": [40,35],
   "y": [800,3200],
   "scale": [1.5,2.5],
   "rotate": [0,0]
 },
 {
   "id": 28,
   "type": "2024 Trend",
   "name": "Love Potion-ing",
   "text": "The (sort of) serious attempt to win someone’s heart by using spells, potions, or elixirs — and maybe even a witch. Witching hour: 17% of surveyed singles say that to help their dating life, they plan to use crystals for love and attraction.",
   "component": LovePotionIng,
   "file": "love-potion-ing.svg",
   "x": [45,35],
   "y": [1025,3375],
   "scale": [2.5,3],
   "rotate": [0,-5]
 },
 {
   "id": 33,
   "type": "2024 Trend",
   "name": "Run Club",
   "text": "Singles are intentionally joining running groups and intramural sports, not just for fitness but to meet new people with shared interests. Run together, stay together: 28% of surveyed singles say \"shared interests\" will top their list of must-haves in 2025.",
   "component": RunClub,
   "file": "run-club.svg",
   "x": [62.5,60],
   "y": [950,3300],
   "scale": [2,3],
   "rotate": [10,10]
 },
 {
   "id": 38,
   "type": "2024 Trend",
   "name": "Slow A-Peel",
   "text": "Dating with a mindful approach, like peeling back the layers of an onion. It’s all about embracing the mystery, revealing each part of yourself gradually. When asked what best describes their dating life, 27% of surveyed Tinder singles said slow dating.",
   "component": SlowAPeel,
   "file": "slow-a-peel.svg",
   "x": [80,80],
   "y": [930,3300],
   "scale": [1.5,3],
   "rotate": [0,0]
 },
 {
   "id": 26,
   "type": "2024 Trend",
   "name": "Limerence",
   "text": "That all-consuming crush where you’re completely obsessed, longing for them to feel the same — often fueled by Tarot readings and daydreams, even if the fantasy differs from the reality. Manifesting magic: 16% of surveyed singles say that to help their dating life, they plan to get a Tarot card reading.",
   "component": Limerence,
   "file": "limerence.svg",
   "x": [15,15],
   "y": [880,3450],
   "scale": [2.5,3.5],
   "rotate": [0,0]
 },
 {
   "id": 13,
   "type": "2024 Trend",
   "name": "Dry Dating",
   "text": "Dry dating is all about connecting with someone without the liquid courage. It's a clear-headed way to get to know each other and avoid being influenced under the influence. In 2025, 39% of surveyed Tinder singles plan to prioritize sober dates.",
   "component": DryDating,
   "file": "dry-dating.svg",
   "x": [85,70],
   "y": [1000,3500],
   "scale": [1,3],
   "rotate": [0,0]
 },
 {
   "id": 27,
   "type": "2024 Trend",
   "name": "Loud Breakups",
   "text": "Step aside relationship \"soft launches\" — hard launching breakups are in. Newly single folks are announcing their splits loud and proud.",
   "component": LoudBreackupsEra,
   "file": "loud-breackups-era.svg",
   "x": [20,40],
   "y": [970,3550],
   "scale": [2,2.5],
   "rotate": [0,0]
 },
 {
   "id": 12,
   "type": "2024 Trend",
   "name": "Delusionship",
   "text": "A relationship that exists more in your head than in real life — where fantasy overshadows reality.",
   "component": Delusionship,
   "file": "delusionship.svg",
   "x": [25,0],
   "y": [1050,3700],
   "scale": [1,3],
   "rotate": [0,0]
 },
 {
   "id": 35,
   "type": "2024 Trend",
   "name": "Season One Stars",
   "text": "When a blast from the past makes a surprise reappearance — but you're already on Season 6, baby.",
   "component": SeasonOneStars,
   "file": "season-one-stars.svg",
   "x": [0,30],
   "y": [1025,3700],
   "scale": [2.5,2.5],
   "rotate": [0,0]
 },
 {
   "id": 4,
   "type": "2024 Trend",
   "name": "Black Cat Girlfriend",
   "text": "Mysterious, reserved, and a little anti-social, but fiercely loyal to those she loves. She's got that unbothered, almost selfish black-cat energy — definitely not one to chase after anyone. Claws out: 23% of our surveyed singles are on the lookout for a \"black cat\" in 2025.",
   "component": BlackCatGirlfriend,
   "file": "black-cat-girlfriend.svg",
   "x": [78,50],
   "y": [1050,3700],
   "scale": [2,4],
   "rotate": [0,0]
 },
 {
   "id": 39,
   "type": "2024 Trend",
   "name": "Smashing the Date-triarchy",
   "text": "Breaking free from traditional gender-based dating pressures and milestones, singles are redefining love and relationships to prioritize their own happiness and personal desires.",
   "component": SmashingTheDatriarchy,
   "file": "smashing-the-datriarchy.svg",
   "x": [58,70],
   "y": [850,3700],
   "scale": [2,3],
   "rotate": [0,0]
 },
 {
   "id": 77,
   "type": "2024 Data",
   "name": "Year In Swipe",
   "text": "",
   "component": YearInSwipeFinal,
   "file": "year-in-swipe-logo-final.svg",
   "x": [100,100],
   "y": [100,100],
   "scale": [1.25,2.4],
   "rotate": [0,0]
 },
]

export default icons;